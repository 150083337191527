<template>
  <div>
    <!-- <Header /> -->
    <div class="container" v-if="isDataLoaded">
      <div class="public-crumbs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <a @click="toIndex()">首页</a>
          </el-breadcrumb-item>

          <el-breadcrumb-item>
            <a @click="toProductList()">商品列表</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="public-shop">
        <div class="picture">
          <el-carousel style="height: 300px">
            <el-carousel-item
              v-for="(v, k, i) in productDetail.pictureList"
              :key="i"
            >
              <el-image :src="v.url">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-carousel-item>
          </el-carousel>

          <div>
            <el-tag
              type="success"
              effect="plain"
              size="medium"
              style="margin-right: 5px"
            >
              商品编号：{{ productDetail.id }}
            </el-tag>

            <el-tag
              type="success"
              size="medium"
              @click="shareProducts()"
              effect="plain"
              style="margin: 5px"
            >
              <i class="el-icon-share"></i> 分享商品
            </el-tag>
          </div>
        </div>
        <div class="info">
          <div class="title">
            <em>{{ productDetail.productName }}</em>
            <div
              style="
                color: #8c8c8c;
                font-size: 14px;
                padding: 10px 0px 10px 0px;
              "
            >
              {{ productDetail.productSubtitle }}
            </div>
            <div class="label">
              <!-- <span>官方自营</span> -->
              <!-- <span>
                <el-tag effect="dark" size="medium"> 热销商品 </el-tag>
              </span> -->

              <div>
                <el-tag effect="dark" size="medium">
                  {{ productDetail.inventoryState }}
                </el-tag>
              </div>
            </div>
          </div>
          <div class="money">
            <div style="color: #fff; font-size: 18px">
              <span>￥</span>
              <span style="font-size: 32px">{{ productDetail.price }}</span>
            </div>
            <div class="costprice">原价：{{ productDetail.parValue }}</div>
          </div>

          <br />
          <div class="placeOrder">
            <el-form ref="form" label-width="120px">
              <orderParam
                :orderParams="productDetail.orderParams"
                v-model="productDetail.orderParams"
              >
              </orderParam>

              <el-form-item label="购买数量">
                <el-input-number
                  v-model="orderForm.quantity"
                  :min="productDetail.dealQuantityMin"
                  :max="productDetail.dealQuantityMax"
                  label="描述文字"
                ></el-input-number>

                <el-tag type="success" style="margin-left: 10px">
                  库存:{{ productDetail.inventoryQuantity }}
                </el-tag>
              </el-form-item>
            </el-form>
          </div>

          <div class="distribution">
            <!-- <div class="mode z2">手动发货</div> -->
            <div class="tips" @click="dialogShippingAgreement = true">
              我已经阅读，理解并接受<span>《虚拟商品发货规则》</span>
            </div>
          </div>
          <a href="#" class="buybut" @click="createOrder()"
            >立即购买 ￥{{
              $calculateProductPrice(
                orderForm.quantity,
                productDetail.price,
                productDetail.orderParams
              )
            }}
          </a>
        </div>
      </div>
      <div class="public-shop-info">
        <div class="title">购买说明</div>
        <div class="body">
          <div
            style="background-color: #ffffff"
            v-html="productDetail.productDescription"
          ></div>
        </div>
      </div>
    </div>

    <!-- 充值提示 -->
    <el-dialog
      title="充值提示"
      :visible.sync="popupTipVisible"
      width="40%"
      append-to-body
    >
      <p style="font-size: 16px; padding: 20px">
        <span v-html="productDetail.popupTip"></span>
      </p>
    </el-dialog>

    <el-dialog
      title="商品分享"
      :visible.sync="qrcodeVisible"
      width="30%"
      append-to-body
    >
      <div>
        <vue-canvas-poster
          :painting="painting"
          style="width: 100%"
        ></vue-canvas-poster>
      </div>
    </el-dialog>

    <el-dialog
      title="发货协议"
      :visible.sync="dialogShippingAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.shippingAgreement" style="padding: 10px"></div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Toast } from "vant";
import { queryProductDetail } from "@/api/index";
import "@/assets/pc/css/style.css";
import "@/assets/pc/css/common.css";
import { getSysXieYi } from "@/api/index";
import orderParam from "@/components/orderParam.vue";

export default {
  components: {
    // Header,
    orderParam,
  },
  data() {
    return {
      isDataLoaded: false,
      dialogShippingAgreement: false,
      qrcodeVisible: false,
      painting: {
        width: "550px",
        height: "876px",
        background: require("@/assets/image/1703344720899.png"),

        views: [
          {
            type: "image",
            url: "",
            css: {
              top: "0px",
              left: "25px",
              borderRadius: "0px",
              width: "500px",
              height: "500px",
            },
          },
          // {
          //   type: "text",
          //   text: `邀你体验智一面`,
          //   css: {
          //     top: "80px",
          //     left: "170px",
          //     color: "#000000",
          //     fontSize: "16px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: `前端工程师`,
          //   css: {
          //     top: "220px",
          //     left: "36px",
          //     width: "500px",
          //     color: "#000000",
          //     fontSize: "60px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: `候选人专业技能评测！！！`,
          //   css: {
          //     top: "420px",
          //     left: "36px",
          //     width: "500px",
          //     color: "#000000",
          //     fontSize: "36px",
          //   },
          // },
          {
            type: "text",
            text: ``,
            css: {
              top: "520px",
              left: "36px",
              width: "500px",
              color: "#000000",
              fontSize: "22px",
            },
          },
          {
            type: "text",
            text: `2`,
            css: {
              top: "730px",
              left: "50px",
              width: "500px",
              color: "#000000",
              fontSize: "26px",
            },
          },
          {
            type: "qrcode",
            content: "",
            css: {
              bottom: "90px",
              right: "76px",
              color: "#000",
              background: "#fff",
              width: "100px",
              height: "100px",
              borderWidth: "5px",
              borderColor: "#fff",
            },
          },
          {
            type: "text",
            text: `微信扫码识别`, // 后端返回路径，这里特别坑，想象不到的bug，oss或者自己服务都允许跨域了，但在手机上还是会有出现cors跨域问题，可能是缓存问题，我们在路径上加个时间戳就好了0.0
            css: {
              bottom: "50px",
              right: "72px",
              color: "rgba(255,255,255,255)",
              fontSize: "18px",
            },
          },
          {
            type: "image",
            url: "",
            css: {
              width: "120px",
              height: "31px",
            },
          },
          // ....
        ],
      },
      popupTipVisible: false,
      kefuStatus: false,
      orderPopupStatus: false,
      productDetail: {},
      orderForm: {
        //收件人姓名
        // deliveryPerson: null,
        //县编码
        // deliveryCountry: "",
        //省编码
        // deliveryProvince: null,
        //市编码
        // deliveryCity: null,
        //省名称
        // deliveryProvinceName: "",
        //城市名称
        // deliveryCityName: "",
        //地址
        // deliveryAddress: "",
        //手机号
        // deliveryPhone: "",
        //运费模版
        // deliveryConfigId: 1,
        // deliveryConfig: "",
        //成交平台 0:web 1:ios 2:android 3:wx
        // dealPlatform: 1,
        //备注
        // deliveryRemark: "",
        //邮编
        // deliveryPostcode: "",
        //充值账号
        //充值数量
        quantity: null,
        //商品id
        productId: null,
        orderParams: [],
        stockId: undefined,
      },
      // contactQRUrl: "",
      // contactQQ: "",
      // contactWeChat: "",
      productId: null,
      xieyiObj: {},
    };
  },

  computed: {
    ...mapGetters({}),
    ...mapState({
      productDetail: (state) => state.index.productDetail,
      orderId: (state) => state.order.orderId,
      webSetting: (state) => state.index.webSetting,
    }),
  },
  created() {
    this.loadXieYi();
  },
  beforeRouteEnter(to, from, next) {
    const product_id = to.query.product_id;
    if (product_id) {
      next((vm) => {
        vm.productDetail = {};
        vm.getProductDetail(product_id);
      });
    } else {
      next();
    }
  },
  mounted() {},
  methods: {
    //修改参数
    // updateOrderParams(updatedParams) {
    //   this.productDetail.orderParams = updatedParams;
    // },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 分享商品
     */
    shareProducts() {
      this.qrcodeVisible = true;
      this.painting.views[0].url = this.productDetail.pictureList[0].url;
      this.painting.views[1].text = this.productDetail.productName;
      this.painting.views[2].text = "￥" + this.productDetail.price.toString();
      this.painting.views[3].content = window.location.href;
    },

    //联系客服
    lianxikefu() {
      this.$router.push("/contact");
    },

    //获取获取商品列表
    async getProductDetail(product_id) {
      let data = { product_id: product_id };
      const response = await queryProductDetail(data);
      this.productDetail = response.result;
      if (this.productDetail.popupTip) {
        this.popupTipVisible = true;
      }
      this.isDataLoaded = true;

      //设置网站标题
      document.title =
        response.result.productName +
        " - " +
        this.webSetting.detail.websiteName;
    },

    //提交订单,弹出框输入账号
    purchaseGoods() {
      this.orderPopupStatus = true;
    },

    //提交订单
    createOrder() {
      this.orderForm.productId = this.productDetail.id;
      this.orderForm.orderParams = this.productDetail.orderParams;
      this.orderForm.stockId = this.productDetail.productStock.id;
      this.$store
        .dispatch("order/create", this.orderForm)
        .then(() => {
          this.$router.push({
            path: "/confirmOrder",
            query: { orderId: this.orderId },
          });
        })
        .catch(() => {
          // this.$message({
          //     message: '提交失败！',
          //     type: 'error'
          // });
        });
    },

    toIndex() {
      this.$router.push("/index");
    },

    /**
     * 分享商品
     */
    shareGoods() {
      // 复制邀请链接

      const input = document.createElement("input");
      input.setAttribute("value", window.location.href);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      Toast.success({
        message: "已复制到剪贴板",
      });
    },

    toProductList() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.placeOrder {
  border: 1px solid #dcdee2;
  border-color: #e8eaec;
  align-items: flex-end;
  /* padding: 10px; */
  background: rgb(0 0 0 / 3%);
  border-radius: 5px;
  padding-top: 20px;
  padding-right: 50px;
}

/deep/.el-dialog {
  border-radius: 5px;
}

/deep/.el-dialog__body {
  padding: 0 !important;
}

/deep/.canvas {
  position: relative;
  top: 0px;
  width: 100% !important;
  height: 100% !important;
}

.product_top_lan {
  width: fit-content;
  height: 30px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  /* border: 1px solid #ddd; */
  font-size: 12px;
  color: #999;
  border-radius: 4px;
  margin-left: 12px;
  cursor: pointer;
  transition: 0.3s;
}
</style>
