<template>
  <div>
    <!-- <Header /> -->

    <div class="container">
      <div class="order-content">
        <div class="goods-detail" style="border: 1px solid #cdbdbd">
          <div>
            <div class="title">
              确认订单信息 订单号：{{ orderDetails.serialNum }}
            </div>
            <div class="qr-order-content">
              <span class="goods-type">
                {{ orderDetails.orderFormProduct.productTypeText }}
              </span>
              <div class="el-image">
                <img
                  :src="orderDetails.orderFormProduct.picUrl"
                  class="el-image__inner"
                /><!---->
              </div>
              <div class="row-content">
                <span
                  style="
                    font-size: 18px;
                    font-weight: 700;
                    position: absolute;
                    top: 0;
                    margin-top: 20px;
                  "
                >
                  {{ orderDetails.orderFormProduct.productName }}
                </span>
                <!-- <div class="conter">
                <div class="icon cursor-dis">
                  <i class="el-icon-minus"></i>
                </div>
                <input class="conter-input" />
                <div class="icon">
                  <i class="el-icon-plus"></i>
                </div>
              </div> -->
              </div>
            </div>
          </div>

          <div class="order_spacing">
            <div v-if="this.orderDetails.orderParams">
              <div class="add-info-title">填写充值信息</div>
              <div>
                <el-form ref="form" label-width="120px">
                  <orderParam
                    :orderParams="orderDetails.orderParams"
                    v-model="orderDetails.orderParams"
                  >
                  </orderParam>
                </el-form>
                <!-- <div
                    style="padding: 10px"
                    v-for="(item, key, index) in orderDetails.orderParams"
                    :key="index"
                  >
                    <el-input v-model="item.value" v-if="item.type === '1'">
                      <template slot="prepend">{{ item.name }}</template>
                    </el-input>

                    <el-input
                      v-if="item.type === '2'"
                      :placeholder="item.desc"
                      v-model="item.value"
                    >
                      <template slot="prepend">{{ item.name }}</template>

                      <template slot="append">
                        <el-button
                          type="primary"
                          @click="extractLink(item.value, key)"
                          >提取链接</el-button
                        >
                      </template>
                    </el-input>
                  </div> -->
              </div>
            </div>
          </div>

          <div class="order_spacing">
            <div class="by-info-title">请选择支付方式</div>
            <div class="by-info-content">
              <div class="choose-pay">
                <div class="choose-pay-item">
                  <el-radio-group v-model="payType">
                    <el-radio border label="balance">余额支付</el-radio>
                    <el-radio
                      border
                      v-for="(value, key, index) in payList"
                      :key="index"
                      :label="value.payType"
                      >{{ value.payName }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="pay-computed el-row">
                <!-- <div class="el-row">
                  <span>市场单价：</span
                  ><span> {{ orderDetails.orderFormProduct.parValue }}元</span>
                </div> -->

                <div class="el-row">
                  <span>购买数量：</span>
                  <span> {{ orderDetails.orderFormProduct.quantity }} 件</span>
                </div>
                <div class="el-row">
                  <span style="color: rgb(0, 51, 51)">应付总额：</span>
                  <span style="color: rgb(255, 70, 5); font-weight: bold">
                    {{ orderDetails.amountFinally }}元</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="order_spacing">
            <div class="by-info-content">
              <el-form>
                <el-form-item label="备注">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="orderDetails.deliveryRemark"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <div class="order_spacing">
            <div
              style="
                display: flex;
                justify-content: center;
                padding-bottom: 20px;
              "
            >
              <el-button
                type="primary"
                round
                @click="handleGoBuy()"
                style="
                  display: block;
                  width: 400px;
                  height: 60px;
                  font-size: 22px;
                "
                >立即支付</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <Payment
      ref="paymentComponent"
      :orderId="this.$route.query.orderId"
      :paymentMethod="this.payType"
      :orderParams="this.orderDetails.orderParams"
      :deliveryRemark="this.orderDetails.deliveryRemark"
    ></Payment>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Payment from "@/components/payment/Payment";
import orderParam from "@/components/orderParam.vue";

export default {
  data() {
    return {
      dialogVisible: false,
      spaecsPirce: 0,
      payList: {},
      rechargeAccount: "",
      orderDetails: [],
      queryParam: {
        specsId: undefined,
        rechargeAccount: undefined,
      },
      payMaps: [],
      payType: "balance",
    };
  },
  components: {
    Payment,
    orderParam,
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      orderDetail: (state) => state.order.orderDetail,
      payConfig: (state) => state.index.payConfig,
      orderState: (state) => state.order.orderState,
    }),
  },
  created() {
    //初始化支付通道
    this.initPayConfig();
    //获取获取商品详情
    this.getProductDetail();
  },
  methods: {
    //初始化支付通道
    initPayConfig() {
      let data = {};
      this.$store.dispatch("index/queryPayConfig", data).then(() => {
        this.payList = this.payConfig.payList;
        // this.$forceUpdate();
      });
    },

    //获取获取商品列表
    getProductDetail() {
      let id = this.$route.query.orderId;
      this.$store.dispatch("order/detail", id).then(() => {
        this.orderDetails = this.orderDetail;
        // this.$forceUpdate();
      });
    },

    /**
     * 支付确认关闭事件
     */
    handleClose(done) {
      this.$confirm("确认取消支付？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },

    //支付方法
    handleGoBuy() {
      this.$refs.paymentComponent.toPayment();
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/index");
    },
    /**
     * 返回上页
     */
    onClickLeft() {
      this.$router.back();
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>

<style scoped>
.section .zf_order {
  margin: 18px auto 0;
  /* padding-top: 38px; */
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.section .con {
  margin: 3px auto 0;
  padding-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}

.section .zf_order .col-12 .order {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: center;
}
.section .zf_order .col-12 .order span:first-child {
  color: #999;
  font-size: 15px;
  margin-left: 14px;
}
.section .zf_order .col-12 .order span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.goods_name {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}
.goods_name span:nth-child(2) {
  margin-left: 14px;
}
.yuanmoney {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: left;
}
.yuanmoney span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.pay_type {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.pay_type img {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
}
.pay_type span {
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
}
.code_cs,
.code {
  height: 208px;
  background: #fbfbfb;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
}
.code_cs {
  height: 208px;
  background: #ffffff;
}
.code_cs img {
  position: absolute;
  width: 49px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  padding: 10px;
}
.code {
  border: 5px solid #d8d1d1;
  border-radius: 5px;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
  display: block;
  padding: 3px;
}
.price {
  color: #386cfa;
  width: 100%;
  text-align: center;
  top: 65px;
}
.price span:first-child {
  font-size: 28px;
}
.price span {
  font-weight: 700;
}
.price span:nth-child(2) {
  font-size: 17px;
}
.price span {
  font-weight: 700;
}
.shanxinzha {
  margin-top: 32px;
  width: 100%;
  text-align: center;
}
.shanxinzha img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  /* -webkit-animation: xuanzhuan 0.8s linear infinite; */
}

.shanxinzha span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.shanxinzha span p {
  display: inline-block;
  color: #386cfa;
}
.section--last {
  margin-bottom: 20px;
}

/* 新支付页面 */
.qr-order-content {
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  padding-top: 23px;
}
.qr-order-content .bt {
  width: 100%;
  height: 64px;
  background-color: #eaf2ff;
  color: #0a72ff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 64px;
}

.qr-order-content .box1 {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  /* margin-bottom: 80px;
  padding-bottom: 80px; */
  margin: 20px 0 40px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 5px 10px rgb(0 0 0/5%);
  display: flex;
}

.qr-order-content .box1-1 {
  /* width: 644px; */
  margin: 0 auto;
  /* margin-top: 42px; */
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.qr-order-content .box1-1 ul li {
  line-height: 35px;
  font-size: 16px;
  color: #333333;
}

.qr-order-content .box1-2 {
  /* width: 644px; */
  margin: 0 auto;
  margin-top: 42px;
  overflow: hidden;
}
.qr-order-content .box1-2 ul {
  width: 100%;
}
.qr-order-content .box1-2 ul li {
  float: left;
  width: 131px;
  height: 42px;
  margin-right: 6px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 14px;
  color: #333333;
  line-height: 42px;
  margin-top: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.box1-3 {
  /* width: 644px; */
  overflow: hidden;
  margin: 0 auto;
  /* margin-top: 50px; */
}

.qr-order-content .box1-1 ul li {
  margin-bottom: 10px;
  list-style: none;
}

.qr-order-content .box1-1 ul li span.label {
  display: inline-block;
  width: 90px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.qr-order-content .box1-1 ul li span.value {
  color: #5e72e4;
  font-size: 16px;
  font-weight: bold;
}

.order-content {
  width: 100%;
  margin-top: 20px;
  background: #f9f9fa;
}

.order-content .goods-detail {
  padding: 5px;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
}

.order-content .goods-detail .add-info-title,
.order-content .goods-detail .by-info-title,
.order-content .goods-detail .title {
  color: #303133;
  padding: 16px;
  margin: 0 10px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
  position: relative;
  margin-left: 20px;
}

.order-content .goods-detail .add-info-title:before,
.order-content .goods-detail .by-info-title:before,
.order-content .goods-detail .title:before {
  content: "";
  height: 20px;
  width: 5px;
  background: #2b6fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 1px;
  border-radius: 0.18rem;
  transform: translateY(-50%);
}

.order-content .goods-detail .qr-order-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 180px;
  margin: 0 auto;
  width: 98%;
  padding: 0 0.56rem;
  background: #f9f9fa;
  border-radius: 0.18rem;
}

.order-content .goods-detail .qr-order-content span.goods-type {
  position: absolute;
  top: 0;
  right: 0;
  width: 160px;
  height: 40px;
  background: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-radius: 0 10px 0 10px;
}

.order-content .goods-detail .qr-order-content .el-image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-right: 10px;
}

.order-content .goods-detail .qr-order-content .row-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-content .goods-detail .qr-order-content .conter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.9rem;
  border-radius: 0.1rem;
  border: 1px solid #dcdfe6;
  overflow: hidden;
}

.order-content .goods-detail .qr-order-content .conter:hover {
  border-color: #1890ff;
}

.order-content .goods-detail .qr-order-content .conter .conter-input {
  display: block;
  height: 100%;
  width: 2.5rem;
  font-size: var(--sizeM, 0.25rem);
  outline: none;
  border: none;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
}

.order-content .goods-detail .qr-order-content .conter .icon {
  width: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  height: 100%;
  cursor: pointer;
}

.order-content .goods-detail .qr-order-content .conter .icon:hover {
  color: #1890ff;
}

.order-content .goods-detail .add-info-content {
  display: flex;
}

.order-content .goods-detail .add-info-content .el-form {
  padding-top: 4px;
}

.order-content .goods-detail .by-info-content {
  width: 98%;
  padding: 0.36rem;
  padding-left: 0;
  margin: 0 auto;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  border-top: 0.018rem solid #ddd;
}

.order-content .goods-detail .by-info-content .pay-computed {
  width: 20%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
}

.order-content .goods-detail .by-info-content .pay-computed .el-row {
  margin: 15px auto;
}

.order-content .goods-detail .by-info-content .pay-computed .el-row .sub-btn {
  width: 4.44rem;
  height: 0.89rem;
  background: #1890ff;
  border-radius: 0.1rem;
  font-size: var(--sizeXL, 0.35rem);
}

.order-content .goods-detail .choose-pay {
  display: flex;
  align-items: center;
  padding: 22px 15px;
  gap: 16px;
}

.order-content .goods-detail .choose-pay p {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
}

.order-content .goods-detail .choose-pay .choose-pay-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  width: 160px;
  height: 50px;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item .svg {
  width: 26px;
  height: 26px;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item p {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
}

.order-content .goods-detail .choose-pay .choose-pay-item .active {
  border-color: #1890ff;
}

.order-content .goods-detail .choose-pay .choose-pay-item label {
  white-space: nowrap;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pass-box {
  width: 400px;
}
</style>
