<template>
  <div class="app-container">
    <el-card shadow="never">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="userId" label="用户ID" align="center">
        </el-table-column>
        <el-table-column prop="username" label="用户名" align="center">
        </el-table-column>
        <el-table-column prop="levelName" label="用户等级" align="center">
        </el-table-column>
        <el-table-column
          prop="datelineRegisterReadable"
          label="注册时间"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column label="操作" align="center"> </el-table-column> -->
      </el-table>

      <!-- 分页 -->
      <div style="float: right; margin: 16px 0">
        <pagination
          :total="total"
          :current-page="queryParam.pageNum"
          :page-size="queryParam.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
import { getUserList } from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initData();
      }
    },

    initData() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        getUserList(this.queryParam)
          .then((res) => {
            //主站
            this.tableData = res.result.list;
            this.total = res.result.total;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },

    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },
  },
};
</script>
