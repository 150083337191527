<template>
  <div>
    <!-- 头部 -->
    <!-- <div class="head">
               <van-nav-bar
                left-text="返回"
                left-arrow
				:title="productDetail.specsName"
                @click-left="onClickLeft"
                />
         </div> -->

    <div class="ranta-container-0">
      <div class="ranta-container-0-0">
        <div class="ranta-container-0-0-0">
          <div style="height: 400px">
            <div class="head-wrapper">
              <div class="head-menu">
                <div class="iconfont">
                  <van-icon name="arrow-left" size="20" @click="goBackPage()" />
                </div>
                <div class="iconfont">
                  <van-icon name="wap-home-o" size="21" @click="goBackHome()" />
                </div>
              </div>
            </div>

            <van-swipe
              :autoplay="3000"
              indicator-color="white"
              style="height: 100%"
            >
              <van-swipe-item
                v-for="(v, k, i) in productDetailList.pictureList"
                :key="i"
                @click="handleImageClick(productDetailList.pictureList)"
              >
                <img
                  :src="v.url"
                  style="display: block; width: 100%; height: 100%"
                />
              </van-swipe-item>
            </van-swipe>
          </div>

          <!-- 价格 -->
          <div>
            <div>
              <div class="m-base-info">
                <div class="m-title-block">
                  <div class="goods-title u-base-info-row">
                    <div class="goods-title__tags"></div>
                    <div class="goods-title__box">
                      <div class="goods-title__main">
                        <span class="goods-title__main-text">
                          {{ productDetailList.productName }}</span
                        >

                        <div
                          style="
                            color: #8c8c8c;
                            font-size: 14px;
                            padding: 5px 0px 5px 0px;
                          "
                        >
                          {{ productDetailList.productSubtitle }}
                        </div>
                      </div>
                      <div class="goods-title__more-wrapper">
                        <div class="goods-title__more" @click="shareProducts()">
                          <div
                            class="vant-tee van-icon van-icon-share"
                            style="
                              color: rgb(100, 101, 102);
                              font-size: 18px;
                              display: block;
                            "
                          ></div>
                          <div class="goods-title__more-item-word">分享</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="m-price-block">
                  <div class="goods-price u-base-info-row">
                    <div class="goods-price__current">
                      <span
                        style="font-weight: 500"
                        v-html="$decimalPrice(productDetailList.price)"
                      ></span>

                      <!-- 原价 -->
                      <div
                        class="goods-price__origin u-base-info-row"
                        v-if="productDetailList.parValue"
                      >
                        <div
                          class="goods-price__origin-price"
                          style="
                            color: #c4c7cf;
                            font-weight: 400;
                            font-size: 0.8125rem;
                            margin-left: 5px;
                          "
                        >
                          ￥{{ productDetailList.parValue }}
                        </div>
                      </div>
                    </div>

                    <!-- 标签 -->
                    <div style="position: absolute; top: 0; right: 0">
                      <van-tag
                        type="primary"
                        size="medium"
                        style="border-radius: 5px"
                      >
                        {{ productDetailList.productTypeText }}
                      </van-tag>
                    </div>
                  </div>
                </div>
                <!--  -->

                <!-- <div
                  class="svipCon acea-row row-between-wrapper skeleton-rect"
                  style=""
                >
                  <div class="acea-row row-between-wrapper">
                    <img
                      style="width: 20px; height: 20px; margin-right: 10px"
                      src=""
                      draggable="false"
                    />
                    <div>提升等级 享受更低折扣</div>
                  </div>
                  <div class="svipBtn">立即升级</div>
                </div> -->
                <!--  -->
              </div>

              <div
                style="background: #fff; text-align: center; margin-top: 0px"
              >
                <div style="padding: 10px">
                  <van-row gutter="30">
                    <van-col span="8">
                      <span class="product_detail_ts_title">最小限购</span>
                      <span class="product_detail_ts_value">
                        {{ productDetailList.dealQuantityMin }}份
                      </span>
                    </van-col>
                    <van-col span="8">
                      <span class="product_detail_ts_title">最大限购</span>
                      <span class="product_detail_ts_value">
                        {{ productDetailList.dealQuantityMax }}份
                      </span>
                    </van-col>
                    <van-col span="8">
                      <span class="product_detail_ts_title">库存</span>
                      <span class="product_detail_ts_value">
                        {{ productDetailList.inventoryQuantity }}
                      </span>
                    </van-col>
                  </van-row>
                </div>
              </div>

              <div
                style="
                  background: #fff;
                  text-align: center;
                  border-top: 1px solid #f5f5f5;
                "
              >
                <div style="padding: 10px; font-size: 12px; color: #969799">
                  <van-row gutter="10">
                    <van-col span="6">
                      <div>
                        <img src="@/assets/image/icon_zheng.svg" width="25px" />
                        <span class="icon_name">正品保证</span>
                      </div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <img
                          src="@/assets/image/icon_shouhou.svg"
                          width="25px"
                        />
                        <span class="icon_name">品质溯源</span>
                      </div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <img src="@/assets/image/icon_jisu.svg" width="25px" />
                        <span class="icon_name">专属客服</span>
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div>
                        <img
                          src="@/assets/image/icon_anquan.svg"
                          width="25px"
                        />
                        <span class="icon_name">平台保证</span>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>

              <!-- <div class="guarantee-bar" style="margin-top: 8px">
                <div
                  class="van-cell van-cell--center van-cell--borderless van-cell-clickable guarantee-bar--0"
                  hover-class="van-cell--hover "
                  hover-stay-time="70"
                >
                  <div class="van-cell__title"></div>
                  <div class="van-cell__value">
                    <div class="guarantee-bar__info">
                      <img
                        src="//b.yzcdn.cn/security/fangxin-green.svg"
                        class="tee-image guarantee-bar__logo short-green"
                        alt="护航logo"
                      />
                    </div>
                    <van-row gutter="10">
                      <van-col span="6">
                        <div>
                          <img
                            src="@/assets/image/icon_zheng.svg"
                            width="25px"
                          />
                          <span class="icon_name">正品保证</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div>
                          <img
                            src="@/assets/image/icon_shouhou.svg"
                            width="25px"
                          />
                          <span class="icon_name">品质溯源</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div>
                          <img
                            src="@/assets/image/icon_jisu.svg"
                            width="25px"
                          />
                          <span class="icon_name">专属客服</span>
                        </div>
                      </van-col>

                      <van-col span="6">
                        <div>
                          <img
                            src="@/assets/image/icon_anquan.svg"
                            width="25px"
                          />
                          <span class="icon_name">平台保证</span>
                        </div>
                      </van-col>
                    </van-row>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <!-- 服务 -->
          <!-- <div>
            <div class="group-block">
              <div class="service-block">
                <div class="van-cell">
                  <div class="van-cell__title">
                    <div class="sc__main sc__main--ellipsis">
                      <span class="tee-text sc__title">温馨提示</span>
                      <span>请确保账号输入无误，充值成功后不支持退款</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- 商品详情 -->

          <div>
            <div>
              <div class="goods-price-intro">
                <div class="title">
                  <div class="white-block">商品详情</div>
                </div>
              </div>
              <div style="background-color: #ffffff">
                <div>
                  <van-empty
                    description="暂无商品描述~"
                    v-if="!productDetailList.productDescription"
                  />
                  <div class="cap-richtext" v-else>
                    <span v-html="productDetailList.productDescription"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 充值提示 -->

    <!-- <van-popup
      v-model="popupTipVisible"
      round
      position="bottom"
      :style="{ height: '30%' }"
    >
      <p>{{ this.productDetailList.popupTip }}</p>
    </van-popup> -->

    <!-- 提交订单弹出框 -->
    <van-popup
      v-model="orderPopupStatus"
      closeable
      position="bottom"
      :style="{ height: '55%' }"
    >
      <section class="aui-scrollView">
        <div class="aui-pay-box">
          <div class="goodsInfo" style="padding: 15px">
            <van-card
              :price="productDetailList.price"
              :title="productDetailList.productName"
              :thumb="productDetailList.productImg"
            />
          </div>

          <van-form>
            <van-cell-group inset>
              <orderParam
                :orderParams="productDetailList.orderParams"
                v-model="productDetailList.orderParams"
              >
              </orderParam>
              <!-- <div
                v-for="(item, key, index) in this.productDetailList.orderParams"
                :key="index"
              >
                <van-field
                  v-model="item.value"
                  :name="item.name"
                  :label="item.name"
                  :placeholder="item.desc"
                />
              </div> -->

              <van-field name="stepper" label="购买数量">
                <template #input>
                  <van-stepper
                    input-width="100px"
                    v-model="orderForm.quantity"
                    :min="productDetailList.dealQuantityMin"
                    :max="productDetailList.dealQuantityMax"
                  />
                </template>
              </van-field>
            </van-cell-group>

            <div style="margin: 16px">
              <van-button
                round
                block
                type="danger"
                native-type="submit"
                @click="createOrder()"
              >
                提交订单￥{{
                  $calculateProductPrice(
                    orderForm.quantity,
                    productDetailList.price,
                    productDetailList.orderParams
                  )
                }}
              </van-button>
            </div>
          </van-form>
        </div>
      </section>
    </van-popup>

    <van-dialog
      v-model="qrcodeVisible"
      title="商品分享"
      confirm-button-text="关闭"
    >
      <div class="mFenXiang" style="position: relative; top: 0px">
        <vue-canvas-poster
          :painting="painting"
          style="position: relative; top: 0px"
        ></vue-canvas-poster>
      </div>
    </van-dialog>

    <van-goods-action safe-area-inset-bottom>
      <van-goods-action-icon icon="shop-o" text="首页" @click="toIndex" />

      <van-goods-action-icon
        v-if="collectStatus"
        icon="like"
        text="已收藏"
        color="#FF638C"
      />

      <van-goods-action-icon
        v-else
        icon="like-o"
        text="收藏"
        @click="collectProducts"
      />

      <van-goods-action-icon icon="chat-o" text="客服" @click="lianxikefu" />
      <!-- 直冲购买 -->
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="purchaseGoods"
      />
    </van-goods-action>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
// import { Toast } from "vant";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import { addCollectProducts, addBrowseProducts } from "@/api/product";
import orderParam from "@/components/mOrderParam.vue";

export default {
  components: {
    orderParam,
  },
  data() {
    return {
      collectStatus: false,
      qrcodeVisible: false,
      painting: {
        width: "550px",
        height: "876px",
        background: require("@/assets/image/1703344720899.png"),
        views: [
          {
            type: "image",
            url: "",
            css: {
              top: "20px",
              left: "35px",
              borderRadius: "10px",
              width: "480px",
              height: "480px",
            },
          },
          // {
          //   type: "text",
          //   text: `邀你体验智一面`,
          //   css: {
          //     top: "80px",
          //     left: "170px",
          //     color: "#000000",
          //     fontSize: "16px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: `前端工程师`,
          //   css: {
          //     top: "220px",
          //     left: "36px",
          //     width: "500px",
          //     color: "#000000",
          //     fontSize: "60px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: `候选人专业技能评测！！！`,
          //   css: {
          //     top: "420px",
          //     left: "36px",
          //     width: "500px",
          //     color: "#000000",
          //     fontSize: "36px",
          //   },
          // },
          {
            type: "text",
            text: ``,
            css: {
              top: "520px",
              left: "36px",
              width: "480px",
              color: "#000000",
              fontSize: "22px",
            },
          },
          {
            type: "text",
            text: `2`,
            css: {
              top: "730px",
              left: "50px",
              width: "500px",
              color: "#000000",
              fontSize: "26px",
            },
          },
          {
            type: "qrcode",
            content: "",
            css: {
              bottom: "90px",
              right: "76px",
              color: "#000",
              background: "#fff",
              width: "100px",
              height: "100px",
              borderWidth: "5px",
              borderColor: "#fff",
            },
          },
          {
            type: "text",
            text: `微信扫码识别`, // 后端返回路径，这里特别坑，想象不到的bug，oss或者自己服务都允许跨域了，但在手机上还是会有出现cors跨域问题，可能是缓存问题，我们在路径上加个时间戳就好了0.0
            css: {
              bottom: "50px",
              right: "72px",
              color: "rgba(255,255,255,255)",
              fontSize: "18px",
            },
          },
          {
            type: "image",
            url: "",
            css: {
              width: "120px",
              height: "31px",
            },
          },
          // ....
        ],
      },
      popupTipVisible: false,

      kefuStatus: false,
      orderPopupStatus: false,
      productDetailList: [],
      orderForm: {
        //收件人姓名
        // deliveryPerson: null,
        //县编码
        // deliveryCountry: "",
        //省编码
        // deliveryProvince: null,
        //市编码
        // deliveryCity: null,
        //省名称
        // deliveryProvinceName: "",
        //城市名称
        // deliveryCityName: "",
        //地址
        // deliveryAddress: "",
        //手机号
        // deliveryPhone: "",
        //运费模版
        // deliveryConfigId: 1,
        // deliveryConfig: "",
        //成交平台 0:web 1:ios 2:android 3:wx
        // dealPlatform: 1,
        //备注
        // deliveryRemark: "",
        //邮编
        // deliveryPostcode: "",
        //充值账号
        rechargeAccount: "",
        //充值数量
        quantity: 1,
        //商品id
        productId: null,
        stockId: undefined,
      },

      orderParams: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productDetail: (state) => state.index.productDetail,
      orderId: (state) => state.order.orderId,
      webSetting: (state) => state.index.webSetting,
    }),
  },
  created() {
    //获取获取商品列表
    this.getProductDetail();
    //进来就浏览商品
    this.browseProducts();
  },

  methods: {
    /**
     * 浏览商品
     */
    async browseProducts() {
      let data = { productId: this.$route.query.product_id };
      await addBrowseProducts(data);
    },

    /**
     * 收藏商品
     */
    collectProducts() {
      let data = { productId: this.$route.query.product_id };
      new Promise((resolve, rejust) => {
        addCollectProducts(data)
          .then(() => {
            this.collectStatus = true;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //联系客服
    lianxikefu() {
      this.$router.push("/contact");
    },

    /**
     * 点击放大图片
     */
    handleImageClick(imgList) {
      const resultList = [];
      for (const iterator of imgList) {
        resultList.push(iterator.url);
      }
      ImagePreview(resultList);
    },

    /**
     * 分享商品
     */
    shareProducts() {
      this.qrcodeVisible = true;
      this.painting.views[0].url = this.productDetailList.pictureList[0].url;
      this.painting.views[1].text = this.productDetailList.productName;
      this.painting.views[2].text =
        "￥" + this.productDetailList.price.toString();
      this.painting.views[3].content = window.location.href;
    },

    //获取获取商品列表
    getProductDetail() {
      let data = { product_id: this.$route.query.product_id };
      this.$store.dispatch("index/queryProductDetail", data).then(() => {
        this.productDetailList = this.productDetail;
        this.collectStatus = this.productDetailList.collectStatus;
        if (this.productDetailList.popupTip) {
          Dialog.alert({
            title: "充值提示",
            message: this.productDetailList.popupTip,
            theme: "round-button",
          }).then(() => {});
        }

        //设置网站标题
        document.title =
          this.productDetail.productName +
          " - " +
          this.webSetting.detail.websiteName;
      });
    },

    //提交订单,弹出框输入账号
    purchaseGoods() {
      this.orderPopupStatus = true;
    },

    //提交订单
    createOrder() {
      //判断充值账号是否为空
      if (
        this.orderForm.productType == "1" &&
        this.orderForm.rechargeAccount == ""
      ) {
        this.$message({
          message: "请填写充值账号",
        });
        return false;
      }

      this.orderForm.productId = this.$route.query.product_id;
      this.orderForm.orderParams = this.productDetailList.orderParams;
      this.orderForm.stockId = this.productDetailList.productStock.id;

      this.$store
        .dispatch("order/create", this.orderForm)
        .then(() => {
          this.$router.push({
            path: "/payment",
            query: { orderId: this.orderId },
          });
        })
        .catch(() => {
          // this.$message({
          //     message: '提交失败！',
          //     type: 'error'
          // });
        });
    },

    toIndex() {
      this.$router.push("/mindex");
    },

    // touchEnd() {
    //   //手指离开
    //   clearTimeout(this.timer);
    // },

    // touchStart() {
    //   //手指触摸
    //   clearTimeout(this.timer); //再次清空定时器，防止重复注册定时器
    //   this.timer = setTimeout(() => {
    //     this.downloadIamge(this.qrcodeUrl64, "pic");
    //   }, 1000);
    // },

    /**
     * 返回上一页
     */
    goBackPage() {
      this.$router.go(-1);
    },

    /**
     * 返回主页
     */
    goBackHome() {
      this.$router.push("/mindex");
    },
  },
};
</script>
<style>
.ranta-container-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  padding-bottom: 85px;
  background-color: #f7f8fa;
  background-size: 100% auto;
  color: #323233;
}

.ranta-container-0-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.ranta-container-0-0-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.image-block.is-inited {
  width: 100%;
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* overflow: hidden; */
}

.image-block {
  position: relative;
  background-color: #f7f8fa;
  /* height: 375px; */
}

.image-block-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-swipe {
  height: 100%;
}

.tee-swipe {
  position: relative;
  /* -webkit-user-select: none; */
  user-select: none;
  overflow: hidden;
}

.tee-swipe__track {
  display: flex;
}

.tee-swiper-item {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
}

.image-swipe-item {
  max-width: 100%;
  object-fit: contain;
}

.m-base-info {
  padding: 0 16px 12px;
  background: #fff;
}

.m-price-block {
  position: relative;
  background-color: #fff;
  padding-top: 12px;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  line-height: 18px;
}

.u-base-info-row {
  font-size: 13px;
}

.goods-price {
  position: relative;
  text-align: left;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
}

.goods-price__current {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: end;
  /* -webkit-align-items: flex-end; */
  align-items: flex-end;
  margin-right: 8px;
  vertical-align: middle;
  font-size: 16px;
  color: var(--price, #323233);
}

.goods-price__current-label {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-right: 2px;
  font-weight: 700;
  position: relative;
}

.goods-price__current-price {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  line-height: 24px;
  font-weight: bolder;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  line-height: 18px;
}

.u-base-info-row {
  font-size: 13px;
}

.goods-price__origin {
  /* position: relative; */
  text-align: left;
  display: block;
  /* font-size: 12px; */
  color: #969799;
  /* line-height: 16px; */
  margin: 4px 0 0;
}

.goods-price__origin-label {
  margin-right: 4px;
}

.goods-price__origin-price {
  display: inline-block;
  text-decoration: line-through;
}

.m-title-block {
  position: relative;
  background-color: #fff;
  padding-top: 12px;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
}

.goods-title__tags:empty {
  margin-bottom: 0;
}

.goods-title__box {
  position: relative;
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -webkit-flex-direction: row; */
  flex-direction: row;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  min-height: 26px;
}

.goods-title__main {
  -webkit-box-flex: 1;
  /* -webkit-flex: 1; */
  flex: 1;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  font-size: 0;
  font-weight: 500;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
}

.goods-title__main-text {
  display: inline;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 20px;
  font-size: 1rem;
  color: #333333;
  font-weight: 600;
}

.goods-title__more-wrapper {
  position: relative;
  height: 100%;
  width: 24px;
  min-height: 36px;
}

.goods-title__more {
  position: absolute;
  left: 0;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  transform: translateY(-50%);
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* -webkit-flex-direction: column; */
  flex-direction: column;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  color: #969799;
}

.goods-title__more-item-word {
  margin-left: 2px;
  font-size: 12px;
  line-height: normal;
  white-space: nowrap;
}

.van-cell--center {
  /* -webkit-align-items: center; */
  align-items: center;
}

.van-cell {
  position: relative;
  /* display: -webkit-flex; */
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  padding: var(--cell-vertical-padding, 10px)
    var(--cell-horizontal-padding, 16px);
  /* font-size: 14px; */
  font-size: var(--cell-font-size, 14px);
  /* line-height: 24px; */
  line-height: var(--cell-line-height, 24px);
  /* color: #323233; */
  color: var(--cell-text-color, #323233);
  /* background-color: #fff; */
  background-color: var(--cell-background-color, #fff);
}

.van-cell__title:empty {
  display: none;
}

/* .van-cell__title {
  flex: 1;
} */

/* .van-cell__value {
  flex: 1;
} */

.van-cell__value {
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  /* color: #969799; */
  color: var(--cell-value-color, #969799);
}

.guarantee-bar__info {
  height: 24px;
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.guarantee-bar__logo.short-green {
  width: 59px;
  height: 16px;
}

.guarantee-bar__desc {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 6px;
  color: #969799;
  overflow: hidden;
}

.goods-stock-block {
  margin-top: 8px;
  font-size: 14px;
}

.goods-stock-block .stock-cell__main {
  display: flex;
  color: #323233;
}

.goods-stock-block .stock-cell__title {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
  margin-right: 12px;
  color: #969799;
}

.goods-stock-block .goods-stock {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  width: 100%;
}

.goods-stock-block .goods-stock .stock {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  color: #969799;
}

.goods-stock-block .goods-stock .stock .stock-content {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
}

.goods-stock-block .goods-stock .stock {
  /* display: -webkit-box;
	    display: -webkit-flex;
	    display: flex; */
  color: #969799;
}

.group-block {
  margin-top: 8px;
}

.service-block .sc__main--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.service-block .sc__main {
  /* display: -webkit-box;
	    display: -webkit-flex;
	    display: flex; */
  color: #323233;
}

.service-block .sc__title {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
  margin-right: 12px;
  color: #969799;
}

.cap-richtext {
  padding: 10px;
  overflow-x: hidden;
}

.cap-richtext img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.goods-price-intro {
  position: relative;
  box-sizing: border-box;
  font-size: 12px;
  color: #969799;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 8px;
}

.goods-price-intro .title {
  position: relative;
  margin: 0 16px;
  text-align: center;
  color: #323233;
  line-height: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.goods-price-intro .white-block {
  display: inline-block;
  height: 100%;
  background: #fff;
  padding: 0 8px;
  position: absolute;
  /* z-index: 1; */
  /* -webkit-transform: translateX(-50%); */
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: 600;
}

.goods-price-intro .icon,
.goods-price-intro .icon-rotate {
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #dcdee0;
  /* -webkit-transform: rotate(90deg);
	    transform: rotate(90deg);
	    -webkit-transition: -webkit-transform .3s;
	    transition: -webkit-transform .3s;
	    transition: transform .3s;
	    transition: transform .3s,-webkit-transform .3s; */
}

.goods-price-intro .content-show {
  height: auto;
  margin: 0 16px 16px;
}

.goods-price-intro .content {
  background-color: #f7f8fa;
  border-radius: 4px;
  /* height: 0; */
  box-sizing: border-box;
  /* -webkit-transition: all .3s; */
  transition: all 0.3s;
  overflow: hidden;
  /* margin: 0 16px; */
}

.goods-price-intro .block-top {
  margin-top: 12px;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

.goods-price-intro .text {
  display: inline;
  line-height: 20px;
  font-size: 12px;
  color: #333;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

.goods-price-intro .block--small {
  font-size: 12px;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

/* 顶部返回 */
.head .van-nav-bar__content {
  background-color: #ffffff;
}

.head .van-nav-bar__text {
  color: #000000;
  font-weight: 400;
}

/* .head .van-nav-bar .van-icon {
    color: #ffffff;
} */
/deep/.el-dialog {
  border-radius: 5px;
}

/deep/.el-dialog__body {
  padding: 0 !important;
}

.mFenXiang .canvas {
  position: relative;
  top: 0px;
  width: 100% !important;
  height: 100% !important;
}

/* 自己的 */
.product_detail_ts_title {
  font-size: 13px;
  color: #666;
  margin-right: 5px;
}

.product_detail_ts_value {
  font-size: 13px;
  color: rgb(76, 175, 80);
}

.icon_name {
  padding: 0 2px;
  font-weight: 400;
  color: #9d9d9d;
  font-size: 12px;
}

/* 商品详情返回 */
.head-wrapper {
  z-index: 99;
  display: flex;
  align-items: center;
  position: fixed;
  left: 16px;
  top: 0;
  height: 65px;
}

.head-wrapper .head-menu {
  display: flex;
  align-items: center;
  height: 30px;
  width: 120px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.head-wrapper .head-menu .icon-fanhuijiantou {
  border-right: 1px solid #fff;
}

.page-detail .head-wrapper .head-menu .iconfont {
  flex: 1;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

.head-wrapper .head-menu .iconfont {
  flex: 1;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

/* vip升级页面 */

.svipCon {
  height: 34px;
  padding: 0 10px;
  margin: 10px 10px 0;
  background: #fae3bb;
  font-size: 12px;
  color: #b37400;
  border-radius: 5px;
}
.svipCon uni-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.svipCon .svipBtn {
  font-size: 11px;
  color: #b37400;
}
</style>
