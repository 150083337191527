<template>
  <div>
    <el-row :gutter="20" shadow="never">
      <el-col :span="6">
        <div class="box-item">
          <div class="left">
            <div class="title">总销售额</div>
            <div class="num">￥{{ indexStatis.amount }}</div>
          </div>
          <div class="right">
            <div class="circle">
              <i class="el-icon-pie-chart"></i>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="box-item">
          <div class="left">
            <div class="title">盈利总金额</div>
            <div class="num">￥{{ indexStatis.profitAmount }}</div>
          </div>
          <div class="right">
            <div class="circle">
              <i class="el-icon-pie-chart"></i>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="box-item">
          <div class="left">
            <div class="title">订单总数量</div>
            <div class="num">{{ indexStatis.orderNum }}</div>
          </div>
          <div class="right">
            <div class="circle">
              <i class="el-icon-pie-chart"></i>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="box-item">
          <div class="left">
            <div class="title">用户总数量</div>
            <div class="num">{{ indexStatis.userNum }}</div>
          </div>
          <div class="right">
            <div class="circle">
              <i class="el-icon-pie-chart"></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-card shadow="never" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span>商品统计</span>
      </div>

      <div>
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in productStatis" :key="index">
            <div class="item-box">
              <span>{{ item.label }}</span>
              <h4>{{ item.count }}</h4>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card shadow="never" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span>订单统计</span>
      </div>

      <div>
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in orderStatis" :key="index">
            <div class="item-box">
              <span>{{ item.label }}</span>
              <h4>{{ item.count }}</h4>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import "@/assets/pc/css/style.css";
import "@/assets/pc/css/common.css";
import {
  queryProductStatis,
  queryOrderStatis,
  queryIndexStatis,
} from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      productStatis: [],
      orderStatis: [],
      indexStatis: [],
      queryParam: {},
    };
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initLoad();
        this.getOrderStatis();
        this.getIndexStatis();
      }
    },

    //分站
    initLoad() {
      new Promise((resolve, rejust) => {
        queryProductStatis()
          .then((res) => {
            //分站
            this.productStatis = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    getIndexStatis() {
      new Promise((resolve, rejust) => {
        queryIndexStatis()
          .then((res) => {
            //分站
            this.indexStatis = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    getOrderStatis() {
      new Promise((resolve, rejust) => {
        queryOrderStatis()
          .then((res) => {
            //分站
            this.orderStatis = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let state = ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        state = `🌞 早上好!`;
      } else if (hours > 10 && hours <= 11) {
        state = `🌞 上午好!`;
      } else if (hours > 11 && hours <= 13) {
        state = `🌼 中午好!`;
      } else if (hours > 13 && hours <= 18) {
        state = `🌼 下午好!`;
      } else if (hours > 18 && hours <= 24) {
        state = `😴 晚上好!`;
      }
      return state;
    },
  },
};
</script>

<style>
.box-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
}

.box-item .left {
  padding: 20px;
}

.box-item .left .title {
  margin-bottom: 20px;
  font-size: 14px;
}

.box-item .left .num {
  margin-bottom: 30px;
  font-size: 30px;
}

.box-item .left .result {
  white-space: nowrap;
}

.box-item .right {
  padding-right: 20px;
}

.box-item .right .circle {
  width: 70px;
  height: 70px;
  line-height: 80px;
  text-align: center;
  background-color: #edf3fe;
  border-radius: 50%;
}

.box-item .right .circle i {
  font-size: 30px;
  color: #4f89f3;
}

/* 新的 */
.item-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
</style>
