<template>
  <div>
    <div v-for="(item, index) in orderParams" :key="index">
      <van-field
        v-if="item.type === '1'"
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      />

      <van-field
        v-else-if="item.type === '2'"
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="extractLink(item.value, index)"
            >提取链接</van-button
          >
        </template>
      </van-field>

      <van-field
        v-else-if="item.type === '4'"
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="extractLink(item.value, index)"
            >提取链接</van-button
          >
        </template>
      </van-field>

      <van-field
        v-else
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderParams"],
  data() {
    return {
      orderParamList: [],
    };
  },

  methods: {
    extractLink(value, index) {
      if (value === "") {
        this.$message({
          message: "请输入参数",
          type: "error",
        });
        return;
      }
      this.orderParams[index].value = this.$extractLink(value);
    },
  },
};
</script>
